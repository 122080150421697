import { useState } from "react";

function ShowMore(props) {
    const  [show, setShow] = useState(true);
    const array = props.children
    const children = show ? array.slice(0,props.showNum) : props.children
    return (
        <div className="skillsContainer">
            {children}
            <button onClick={() => setShow(!show)}>Voir {show ? 'plus' : 'moins'}</button>
        </div>
    );
}

export default ShowMore;