//import { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import "../../assets/styles/swipe.scss";
import '../../assets/styles/project.scss'
import json from '../../project.json'
// import required modules
import { FreeMode, Pagination } from 'swiper/modules';

function Slider() {
 // const isMobile = window.matchMedia("(max-width: 768px)").matches;
 // const size = isMobile ? 1 : 3;
  return (
    <>
      <Swiper
        /* slidesPerView={size} */
        spaceBetween={30}
        freeMode={true}
        pagination={{
          clickable: true,
        }}
        modules={[FreeMode, Pagination]}
        className="mySwiper"
        breakpoints={{
          // when window width is >= 640px
          640: {
            width: 640,
            slidesPerView: 1,
          },
          // when window width is >= 768px
          /* 768: {
            width: 768,
            slidesPerView: 3,
          }, */
        }}
      >
        {json.project.map((item, index) => {
                    return (
                        <SwiperSlide ><div className='project' key={index} >
                            <div className='info'>
                                <h3>{item.name}:</h3>
                                <p>{item.description}</p>
                                <a href={item.urlRepo} className='linkCard' target="_blank" rel="noreferrer">repo git</a>
                                <br></br>
                                {item.urlSite === '' ? '' : <a href={item.urlSite} className='linkCard' target="_blank" rel="noreferrer">le site en ligne</a>}
                                
                            </div>
                        </div></SwiperSlide>
                    );
                })}
      </Swiper>
    </>
  );
}

export default Slider