import Tag from "../tag";
import "../../assets/styles/hobbies.scss"
import json from "../../project"
function Hobbies(){
    const tags = json.hobbies.map((item,index)=>{
      return(<Tag tag={item.name}key={index}/>);
  });
        return (
            <div className="hobbies">
              <h2>Loisirs :</h2>
              <div className="tags-container">
              {tags}
              </div>
            </div>
        )
}

export default Hobbies;