import React, { useState } from 'react';

function Footer(){
    const email = 'sancheznelson62480@gmail.com';
    const [copied, setCopied] = useState(false);
  const copyEmailToClipboard = () => {
    
    navigator.clipboard.writeText(email)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 1500);
      })
      .catch((err) => console.error('Erreur lors de la copie de l\'adresse e-mail :', err));
  };
    return (
        <footer>
            <div>
                <h2>Me contacter :</h2>
                <ul>
                    <li><a href="https://fr.linkedin.com/" target="_blank" rel="noreferrer">Linkedin</a></li>
                    <li><a href={"mailto:"+ email} target="_blank" rel="noreferrer">{email}</a>
                    <button onClick={copyEmailToClipboard}  style={{ backgroundColor: copied ? 'green' : '#304160' }}>{copied ? 'Copié!' : 'Copier'}</button></li>
                </ul>
            </div>
        </footer>
    )
}

export default Footer;