import Competences from "../components/layout/Competences";
import Header from "../components/layout/Header";
import About from "../components/layout/About";
import Hobbies from "../components/layout/Hobbies";
import Footer from "../components/layout/Footer";
import Slider from "../components/layout/Swipe";
import Parcours from "../components/layout/Parcous";
function App() {
  return (
    <div className="App">
      <Header/>
        <section className="cardsProject">
          <h2>Projets:</h2>
          <div className="projects">
            <Slider/>
          </div>
        </section>
        <section className="competenceAndAbout">
          <Competences/>
          <About/>
        </section>
        <section className="loisireAndParcours">
          <Hobbies/>
          <Parcours/>
        </section>
      <Footer></Footer>
    </div>
  );
}

export default App;
