function About(){
    return (
        <div className="about">
            <h2>A propos de moi:</h2>
            <p>
                Je m'appelle Nelson Sanchez, je suis développeur JavaScript.
                J'ai une passion de longue date pour tout ce qui touche à l'informatique ainsi que tout ce qui stimule ma créativité et ma logique.
                J'adore apprendre et essayer de nouvelles choses. Le développement me permet d'explorer de nouveaux concepts chaque jour,
                c'est pourquoi ce domaine me passionne tant. J'apprécie également aider les gens,
                et mes compétences en informatique me permettent de me sentir utile.
                En aidant les personnes qui ne sont pas familières avec l'informatique et celles qui souhaitent s'y essayer.
            </p>
        </div>
    );
}

export default About