import '../../assets/styles/parcours.scss';
import json from '../../project';
function Parcours () {
  const practice = json.practice.map((item,index)=>{
      return(
        <li>
          <div className='title'>
            <h4>{item.name} :</h4>
            <h5>{item.time}</h5>
          </div>
          <p>{item.description}</p>
        </li>
      );
  });
    return (
        <div className="parcours">
            <h2>Parcours :</h2>
            <div>
              <div className='practice'>
                <h3 >Mon expérience :</h3>
                <ul>
                {practice}
                </ul>
              </div>
              <div className='qualification'>
                <h3>Mes diplome :</h3>
                <ul>
                  <li>Bac commerce. 2015 - 2018.</li>
                  <li>Certification developpeur web. 2023 - (en cours)</li>
                </ul>
              </div>
            </div>
        </div>
    )
}

export default Parcours;