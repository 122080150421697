import '../assets/styles/competence.scss'

function Competence(props) {
    // variable qui verifie que c'est bien un numero si oui elle verifie que le nombre ne depasse pas 100
    const niveaux = isNaN(props.niveaux) ? 10 : props.niveaux > 100 ? 100 : props.niveaux

    return (
        <div className="competence">
            <div className='containe'>
                <div className='titlePourcentage'>
                    <h2>{props.competence}</h2>
                    <h3 className='pourcentage'>{niveaux}%</h3>
                </div>
                <div  style={{  width: `${niveaux}%`}} className="progressbar">
                    {' '}
                </div>
            </div>
        </div>
    );
}

export default Competence;
