import ShowMore from "../ShowMore";
import Competence from "../competence";
import json from '../../project';
function Competences(){
    let arrayCompetence = []  
    json.skills.map((item, index)=> {
        return (
            arrayCompetence.push(<Competence competence={item.language} niveaux={item.pourcentage}/>)
        )
    })
    return (
        <div className="divCompetences">
            <h2>Competences:</h2>
            <ShowMore  children={arrayCompetence} showNum='3'/>
        </div>
    );
}

export default Competences