function Header(){
    return (
        <header className="App-header">
            <div className="header">
                <h1>Nelson sanchez</h1>
            </div>
        </header>
        
    );
}

export default Header;